import React from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { styled } from "linaria/react";
import GoogleMapReact from "google-map-react";
import { theme } from "../../../../../Shared/Theme/Theme";

export type GoogleMapProps = React.HTMLAttributes<HTMLElement>;

const isClient = typeof window !== "undefined";

const Marker = FaMapMarkerAlt as any;

const center = {
  lat: 48.741394,
  lng: 19.175182,
};

const GoogleMapInner: React.FC<GoogleMapProps> = (props: GoogleMapProps) => {
  return (
    <section className={props.className+ ' bg-gray-50'}>
      {isClient && (
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10524.280184228403!2d19.17010880472413!3d48.74235872220196!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xced256a2a7ed449d!2sKurzy%20pl%C3%A1vania%20pre%20doj%C4%8Dat%C3%A1%20v%20Banskej%20Bystrici!5e0!3m2!1sen!2ssk!4v1613243608570!5m2!1sen!2ssk"
          frameBorder="0"
          allowFullScreen
          aria-hidden="false"
          className="w-full"
          tabIndex={0}
        ></iframe>
      )}
    </section>
  );
};

export const WhereToFindUsMap = styled(GoogleMapInner)<GoogleMapProps>`
  display: flex;
  height: 100%;
  .marker {
    fill: ${theme.color.secondary};
    font-size: 4rem;
    transform: translate(-50%, -100%);
  }
`;
