import React from "react";
import { BaseButton, CtaButton } from "../../../Shared/CtaButton/CtaButton";
import DownloadIcon from "../assets/DownloadDocument.svg";
import { HiOutlineDownload } from 'react-icons/hi'

export interface DocumentDownloadLinkProps {
  className?: string;
  text: string;
  href: string;
  description?: string;
}

export const DocumentDownloadLink = (props: DocumentDownloadLinkProps) => {
  const { href, text, description } = props;
  return (
    <div className="flex flex-col items-center">
      <div className="content">
        <div className="label text-2xl font-medium mb-3">{text}</div>
        <div className="description text-gray-700 font-thin text-base">
          Neúčasť na lekcii je možné oznámiť cez rezervačný systém alebo
          zaslaním SMS do 7.30 toho dňa, v ktorý sa lekcia koná. Presné
          inštrukcie na odhlásenie cez SMS Vám oznámime na 1. lekcii.
        </div>
      </div>
      {/* <div className="icon ml-4">
        <img src={DownloadIcon} alt="" className="object-cover w-36 h-12" />
      </div> */}
      <div className="btn-wrapper flex mt-6 w-full">
        <a href={href} target="_blank" rel="noopener noreferrer">
          <BaseButton className="">
            Stiahnuť
            <div className="icon ml-4 flex items-center">
              <HiOutlineDownload />
            </div>
          </BaseButton>
        </a>
      </div>
    </div>
  );
};
