import { styled } from "linaria/react";
import React from "react";
import WaveImage from "../../../assets/WaveImage.svg";

export interface ContactWaveProps {
  className?: string;
}

const Wave = styled.img`
  width: 100%;
  position: absolute;
  top: 120px;
  z-index: -1;
  display: none;
  filter: opacity(0.5);

  @media (min-width: 1280px) {
    display: flex;
  }
`;

export const ContactWave = (props: ContactWaveProps) => {
  const { className = "" } = props;
  return <Wave src={WaveImage} alt="" />;
};
