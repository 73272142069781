import { styled } from "linaria/react";
import React from "react";
import { SectionHeading } from "../../../../Shared/Headings/SectionHeading";
import { WhereToFindUsMap } from "./components/WhereToFindUsMap";

export interface WhereToFindUsProps {
  className?: string;
}

const MapContainer = styled.div`
  height: 300px;

  @media (min-width: 768px) {
    height: 500px;
  }
`;

export const WhereToFindUs = (props: WhereToFindUsProps) => {
  const { className = "" } = props;
  return (
    <section className={props.className}>
      <MapContainer className="mt-4 rounded-xl overflow-hidden shadow-2xl relative w-full h-96">
        <WhereToFindUsMap />
      </MapContainer>
    </section>
  );
};
