import React, { ReactNode } from "react";

export interface LabelValueProps {
  className?: string;
  label: string;
  value: string | ReactNode;
}

export const LabelValue = (props: LabelValueProps) => {
  const { className = "", label, value } = props;
  return (
    <div className={props.className}>
      <span className="label font-bold">{label}</span>
      <span className="value ml-4">{value}</span>
    </div>
  );
};
