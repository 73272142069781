import React from "react";
import { SectionHeading } from "../../../../../Shared/Headings/SectionHeading";
import { LabelValue } from "../../../../../Shared/LabelValue/LabelValue";

export interface AddressSectionProps {
  className?: string;
}

export const AddressSection = (props: AddressSectionProps) => {
  const { className = "" } = props;
  return (
    <div className={props.className}>
      <SectionHeading>Adresa</SectionHeading>
      <div className="text-lg space-y-2 mt-4 text-gray-600">
        <div>Partizánska cesta 116 (pri Barumke) 974 01 Banská Bystrica</div>
        <LabelValue
          value={
            <a
              href={`tel:048/240 00 40`}
              target="_blank"
              rel="noopener noreferrer"
            >
              048/240 00 40
            </a>
          }
          label="Telefón:"
        />
        <LabelValue
          value={
            <a
              href={`tel:0908 555 158`}
              target="_blank"
              rel="noopener noreferrer"
            >
              0908 555 158
            </a>
          }
          label="Mobil:"
        />
        <LabelValue
          label="Mail:"
          value={
            <a
              href={`mailto:recepcia@monzunbb.sk`}
              target="_blank"
              rel="noopener noreferrer"
            >
              recepcia@monzunbb.sk
            </a>
          }
        />
        <LabelValue
          label="Prevádzková doba:"
          value="Pondelok - Piatok : podľa rezervácií"
        />
      </div>
    </div>
  );
};
