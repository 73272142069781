import React, { useEffect } from "react";
import { RightSection } from "./components/RightSection/RightSection";
import { WhereToFindUs } from "./components/WhereToFindUs/WhereToFindUs";
import { Container } from "../../Shared/Container/Container";
import { ContactWave } from "./components/ContactWave/ContactWave";
import axios from "axios";
import { PageHeading } from "../../Shared/Headings/PageHeading";
import { SectionsWrapper } from "../../Shared/SectionsWrapper/SectionsWrapper";
import { DocumentsToDownload } from "../DocumentsToDownload/DocumentsToDownload";
import { PricingWave } from "../../Pricing/PricingWave/PricingWave";

export interface ContactSectionProps {
  className?: string;
}

export const ContactSection = (props: ContactSectionProps) => {
  const { className = "" } = props;

  return (
    <>
      <Container>
        <PageHeading>Kontakt</PageHeading>
        <SectionsWrapper>
          <section
            className={"grid grid-cols-1 lg:grid-cols-2 gap-20 xl:gap-32"}
          >
            <WhereToFindUs />
            <RightSection />
          </section>
          <DocumentsToDownload />
        </SectionsWrapper>
      </Container>
      <ContactWave />
      {/* <PricingWave/> */}
    </>
  );
};
