import React from "react";
import { ContactSection } from "../components/Contact/ContactSection/ContactSection";
import { Container } from "../components/Shared/Container/Container";
import Layout from "../components/Shared/Layout/Layout";
import { SEO } from "../components/Shared/SEO/SEO";

const ContactPage = () => (
  <Layout>
    <SEO title={"Kontakt"} />
    <ContactSection />
  </Layout>
);

export default ContactPage;
