import React from "react";
import { SectionHeading } from "../../../../../Shared/Headings/SectionHeading";
import { LabelValue } from "../../../../../Shared/LabelValue/LabelValue";

export interface InvoicingSectionProps {
  className?: string;
}

export const InvoicingSection = (props: InvoicingSectionProps) => {
  const { className = "" } = props;
  return (
    <article className={props.className}>
      <SectionHeading>Fakturačné údaje</SectionHeading>
      <div className="text-lg space-y-2 mt-4 text-gray-600">
        <LabelValue label="IČO: " value="52156052" />
        <LabelValue label="DIČ: " value="2120910616" />
        <LabelValue label="IČ DPH: " value="SK2120910616" />
        <LabelValue
          label="Číslo účtu: "
          value="SK39 7500 0000 0040 2965 3660"
        />
      </div>
    </article>
  );
};
