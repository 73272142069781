import React from "react";
import { SectionHeading } from "../../Shared/Headings/SectionHeading";
import { DocumentDownloadLink } from "./components/DocumentDownloadLink";

export interface DocumentsToDownloadProps {
  className?: string;
}

export const DocumentsToDownload = (props: DocumentsToDownloadProps) => {
  const { className = "" } = props;
  return (
    <div className={``}>
      <SectionHeading className="">Dokumenty na stiahnutie</SectionHeading>
      <div className="documents grid grid-cols-1 lg:grid-cols-2 gap-14 lg:gap-28 mt-6 lg:mt-16">
        <DocumentDownloadLink text="Prevádzkový poriadok" href="/prevadzkovy-poriadok.pdf" />
        <DocumentDownloadLink text="Lekárske potvrdenie" href="/Lekárske-potvrdenie-o-zdravotnom-stave-dieťaťa.docx" />
      </div>
    </div>
  );
};
