import React from "react";
import { AddressSection } from "./components/AddressSection";
import { InvoicingSection } from "./components/InvoicingSection";

export interface RightSectionProps {
  className?: string;
}

export const RightSection = (props: RightSectionProps) => {
  const { className = "" } = props;
  return (
    <div className={"flex flex-col space-y-16"}>
      <AddressSection />
      <InvoicingSection />
    </div>
  );
};
